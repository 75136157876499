import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 30 min. `}<meta itemProp="prepTime" content="PT30M" /></h5>
    <h5>{`Cook time: 4 hrs. `}<meta itemProp="cookTime" content="PT4H" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`4-6`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Beef shanks, thawed</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Paprika, ~2 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic powder, ~2 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cayenne, ~1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt, ~1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Black pepper, ~1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Butter, 1 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Onions, 1-2 quartered</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Carrots, 3-5 cut</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Celery, 3-5 stalks cut</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Potatoes, 4-5 cubed</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Garlic, 3-5 cloves chopped</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Thyme, 1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Bay leaf, 1</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Beef stock, 2 cups</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Red wine, 1 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Worcestershire sauce, 1-3 tbs.</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Dutch oven</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Tongs</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Thaw beef overnight</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Quarter onions</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Cut carrots into 1-2 inch pieces</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Cut celery into 1-2 inch pieces</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Cut potatoes into 1 inch cubes</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop garlic</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Dry beef shanks with a paper towel and rub with paprika, garlic powder, cayenne, salt, and pepper. Let sit for at least 30 min.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Heat Dutch oven until hot, add butter.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Once butter is melted, sear beef, about 2-5 min. per side until brown and crusty. Remove from pan, set aside.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Cook onions, carrots, celery, potatoes, garlic, thyme, and bay leaf in the same pan until veggies are slightly tender.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add beef stock, red wine, and Worcestershire sauce. Bring to a simmer.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Return beef to pan and cover.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Roast 1 hr. 15 min. per pound of beef at 325F.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Remove from oven, let rest at least 30 min.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      